import { useState, useEffect } from "react";
import axios from "axios";

const useFetch: (url: string) => [any, boolean, string | null, () => void] = (
  url: string
) => {
  const [state, setState] = useState<{
    error: string | null;
    data: any;
    loading: boolean;
    reload: boolean;
  }>({
    error: null,
    data: [],
    loading: true,
    reload: true,
  });

  useEffect(() => {
    (() => {
      axios
        .get(url)
        .then(({ data }) =>
          setState((state) => ({ ...state, error: null, loading: false, data }))
        )
        .catch((error) =>
          setState((state) => ({ ...state, error, loading: false, data: [] }))
        );
    })();
  }, [url, state.reload]);

  return [
    state.data,
    state.loading,
    state.error,
    () => setState((state) => ({ ...state, reload: !state.reload })),
  ];
};

export default useFetch;
