import { FC, useCallback, useState } from "react";
import {
  Box,
  BoxProps,
  Button,
  CircularProgress,
  filledInputClasses,
  Paper,
  Stack,
  styled,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Title from "../layout/Title";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import CustomSnackbar, { SnackbarType } from "../layout/CustomSnackbar";
import streamSaver from "streamsaver";

interface Version {
  /* Auto incremental ID of the version */
  id: number;
  /* Automatic incremental number to identify the version of the software */
  version: number;
  /* Original filename of the software file */
  originalFilename: string;
  /* Name of the version: The couple name of the software + name of the version should be unique */
  name: string;
  /* Encrypted password with bcrypt (10 rounds) */
  password: string;
  /* Defined if an image exist for this version */
  hasImage: boolean;
  /* Defined if a file exist for this version */
  hasSoftwareFile: boolean;
}

const RootStack = styled(Stack)`
  top: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
`;

interface FimIconProps extends BoxProps {
  backgroundColorIcon: string;
  contentIcon: string;
  heightIcon: number;
  widthIcon: number;
}

const FimIcon = styled(Box)<FimIconProps>`
  &::before {
    background-color: ${({ backgroundColorIcon }) => backgroundColorIcon};
    display: block;
    font-size: ${({ heightIcon }) => heightIcon - 10}px;
    border-radius: 50%;
    height: ${({ heightIcon }) => heightIcon}px;
    width: ${({ widthIcon }) => widthIcon}px;
    line-height: ${({ heightIcon }) => heightIcon + 5}px;
    color: #fff;
    margin-bottom: 3px;
    margin-left: auto;
    margin-right: auto;
    font-family: "fim" !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    speak: none;
    -webkit-font-smoothing: antialiased;
    content: "${({ contentIcon }) => contentIcon}";
    text-align: center;
    vertical-align: center;
  }
`;

const PasswordField = styled(TextField)`
  & .${filledInputClasses.root} {
    border-radius: 5px;
  }

  & .${filledInputClasses.root}:hover::before {
    border-bottom: none !important;
  }

  & .${filledInputClasses.root}:before, & .${filledInputClasses.root}:after {
    border-bottom: none;
  }
`;

const Softwares: FC = () => {
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.up("md"));
  const matchesSM = useMediaQuery(theme.breakpoints.up("sm"));
  const matchesLG = useMediaQuery(theme.breakpoints.up("lg"));

  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState<string>("");
  const [token, setToken] = useState<string>("");
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const [snackbar, setSnackbar] = useState<SnackbarType>({
    severity: "success",
    message: "",
    shouldOpen: false,
  });

  const handleDownloadSoftware = useCallback(() => {
    setLoading(true);
    const url = `${
      process.env.REACT_APP_API_ENDPOINT
    }/softwares/download-software/${encodeURIComponent(
      btoa(password)
    )}?token=${token}`;
    fetch(url).then(async (res: Response) => {
      if (!res.ok) {
        setLoading(false);
        setSnackbar({
          message: (await res.json()).message,
          severity: "error",
          shouldOpen: !snackbar.shouldOpen,
        });
        return;
      }
      setPassword("");
      if (!res || !res.body || !res.headers) return;

      const contentDisposition = res.headers.get("content-disposition") ?? "";
      const fileStream = streamSaver.createWriteStream(
        contentDisposition.split('filename="')[1].slice(0, -1)
      );
      const readableStream = res.body;

      // more optimized
      if (window.WritableStream && readableStream.pipeTo) {
        return readableStream.pipeTo(fileStream).then(() => {
          console.log("done writing");
          setLoading(false);
        });
      }

      const writer = fileStream.getWriter();

      const reader = res.body?.getReader();
      if (!!reader) {
        const pump: () => void = () =>
          reader
            .read()
            .then((res) =>
              res.done ? writer.close() : writer.write(res.value).then(pump)
            );

        pump();
      }
    });
  }, [token, password]);

  const handlePasswordChange = useCallback(
    (password: string) => {
      setPassword(password);
    },
    [setPassword]
  );

  const handleOnVerify = useCallback(
    (token: string) => {
      setToken(token);
    },
    [setToken]
  );

  return (
    <>
      <GoogleReCaptcha
        refreshReCaptcha={refreshReCaptcha}
        onVerify={handleOnVerify}
      ></GoogleReCaptcha>
      <CustomSnackbar {...snackbar}></CustomSnackbar>
      <Title matchesLG={matchesLG} matchesMD={matchesMD} matchesSM={matchesSM}>
        Téléchargement
      </Title>
      <RootStack
        direction={matchesMD ? "row" : "column"}
        alignItems="center"
        justifyContent="center"
        spacing={2}
      >
        <Stack sx={{ maxWidth: matchesMD ? "400px" : "320px" }} spacing={3}>
          <Stack direction="row" justifyContent="space-around">
            <FimIcon
              contentIcon="\73"
              backgroundColorIcon="#93038E"
              heightIcon={60}
              widthIcon={60}
            ></FimIcon>
            <FimIcon
              contentIcon="\61"
              backgroundColorIcon="#FF9623"
              heightIcon={60}
              widthIcon={60}
            ></FimIcon>
            <FimIcon
              contentIcon="\71"
              backgroundColorIcon="#0088CD"
              heightIcon={60}
              widthIcon={60}
            ></FimIcon>
          </Stack>
          <Typography
            color="#2D295C"
            variant="subtitle2"
            fontSize="18px"
            style={{ letterSpacing: "0px", lineHeight: "20px" }}
          >
            FIM Médical fait régulièrement évoluer les logiciels de ses
            appareils, au travers de mises à jour téléchargeables.
          </Typography>
          <Typography
            color="#2D295C"
            variant="subtitle1"
            style={{ letterSpacing: "0px", lineHeight: "18px" }}
          >
            Pour télécharger la mise à jour, rien de plus simple : Munissez-vous
            de votre mot de passe et remplissez le formulaire ci-contre. Le
            téléchargement va se lancer ensuite.
          </Typography>
        </Stack>
        <Paper
          sx={{
            width: matchesMD ? "calc(300px - 64px)" : "calc(320px - 64px)",
            p: 4,
            borderRadius: "8px",
          }}
          elevation={6}
        >
          <Stack alignItems="center" spacing={2}>
            <PasswordField
              variant="filled"
              label="Mot de passe"
              onChange={(event) => handlePasswordChange(event?.target.value)}
            />
            <Button
              sx={{ borderRadius: "20px", px: 6 }}
              size="large"
              variant="contained"
              onClick={handleDownloadSoftware}
              startIcon={loading && <CircularProgress size={20} />}
              disabled={loading}
            >
              Télécharger
            </Button>
          </Stack>
        </Paper>
      </RootStack>
    </>
  );
};

export default Softwares;
