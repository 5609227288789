import { FC, useEffect } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import * as jose from "jose";

const Hello: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  let token: string = localStorage.getItem("token") ?? "";
  useEffect(() => {
    if (location.pathname === "/admin/reset-password") {
      return;
    }

    if (token.length === 0) {
      navigate("/admin/login");
    } else {
      if (process.env.REACT_APP_PUBLIC_KEY !== undefined) {
        jose
          .importSPKI(process.env.REACT_APP_PUBLIC_KEY, "RS256")
          .then((key: any) => {
            jose
              .jwtVerify(token, key)
              .then(({ payload }) => {
                localStorage.setItem("user", JSON.stringify(payload));
                navigate("/admin/dashboard");
              })
              .catch(() => {
                localStorage.setItem("token", "");
                navigate("/admin/login");
              });
          });
      }
    }
  }, [navigate, token]);

  return <Outlet />;
};

export default Hello;
