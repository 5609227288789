import { Stack, Typography } from "@mui/material";
import React from "react";

const AddUser: React.FC = () => {
  return (
    <Stack>
      <Typography variant="h1">Add user</Typography>
    </Stack>
  );
};

export default AddUser;
