import { FC } from "react";
import { Box, Button, styled, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

const TitleContainer = styled(Box)`
  position: relative;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.primary.main};
  padding-top: 1.5rem;
  margin-bottom: 2.5rem;

  &::after {
    content: "";
    height: 70px;
    width: 110%;
    display: block;
    border-radius: 100%;
    background-color: ${({ theme }) => theme.palette.primary.main};
    left: -5%;
    position: absolute;
    bottom: -35px;
  }
`;

const TitleText = styled(Typography)`
  position: relative;
  color: white;
  font-weight: bold;
  z-index: 2;
`;

const HomePageButton = styled("a")`
  position: absolute;
  top: 2rem;
  z-index: 5;
  cursor: pointer;
`;

const DisconnectButton = styled(Button)`
  position: absolute;
  top: 1rem;
  right: 1.5rem;
  z-index: 5;
`;

const Title: FC<{
  children: string;
  matchesMD: boolean;
  matchesSM: boolean;
  matchesLG: boolean;
}> = ({ children, matchesMD, matchesSM, matchesLG }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleOnDisconnect = () => {
    localStorage.removeItem("token");
    navigate("../login");
  };

  return (
    <>
      <TitleContainer>
        <HomePageButton
          href="https://fim-medical.com"
          sx={{
            top: matchesMD ? "2rem" : matchesSM ? "1.5rem" : "1.5rem",
            left: matchesSM ? "1.5rem" : "0.8rem",
          }}
        >
          <img
            src="logo-fim-medical.svg"
            style={{ maxWidth: matchesSM ? "100px" : "70px" }}
            alt=""
          />
        </HomePageButton>
        <TitleText
          variant="h3"
          textAlign="center"
          sx={{ fontSize: matchesMD ? "3rem" : matchesSM ? "2rem" : "1.5rem" }}
        >
          {children}
        </TitleText>
        {location.pathname.startsWith("/admin/dashboard") ? (
          <DisconnectButton
            variant="contained"
            color="secondary"
            onClick={handleOnDisconnect}
          >
            Deconnexion
          </DisconnectButton>
        ) : null}
      </TitleContainer>
    </>
  );
};

export default Title;
