import React from "react";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Hello from "./components/admin/Hello";
import Login from "./components/admin/Login";
import Dashboard from "./components/admin/Dashboard";
import Users from "./components/admin/users/Users";
import AddUser from "./components/admin/users/AddUser";
import UpdateUser from "./components/admin/users/UpdateUser";
import { createRoot } from "react-dom/client";
import theme from "./theme";
import { ThemeProvider } from "@mui/material";
import Softwares from "./components/front/Softwares";
import Home from "./components/Home";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import ResetPassword from "./components/admin/ResetPassword";

const container = document.getElementById("root");
// NOTE: For now let's always target the development auth environment.
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <GoogleReCaptchaProvider
        reCaptchaKey="6LdSzxAhAAAAALH2H7iOsfo93PblpTiraFPyhAl8"
        language="fr"
      >
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />}>
              <Route index element={<Softwares />} />
              <Route path="admin" element={<Hello />}>
                <Route path="login" element={<Login />} />
                <Route path="reset-password" element={<ResetPassword />} />
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="users" element={<Users />}>
                  <Route path="add" element={<AddUser />} />
                  <Route path=":userId" element={<UpdateUser />} />
                </Route>
              </Route>
            </Route>
          </Routes>
        </BrowserRouter>
      </GoogleReCaptchaProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
