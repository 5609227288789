import {
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Modal,
  OutlinedInput,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { FC, useState } from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import SendIcon from "@mui/icons-material/Send";
import CustomSnackbar, { SnackbarType } from "../layout/CustomSnackbar";

interface Form {
  username: string;
  password: string;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 400,
  border: "1px solid rgba(0,0,0,.2)",
  p: 4,
};

const Login: FC = () => {
  const navigate = useNavigate();
  const [values, setValues] = useState<Form>({ username: "", password: "" });
  const [email, setEmail] = useState<string | null>(null);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingForgottenPassword, setLoadingForgottenPassword] =
    useState(false);
  const [snackbar, setSnackbar] = useState<SnackbarType>({
    severity: "error",
    message: "",
    shouldOpen: false,
  });
  const [open, setOpen] = useState(false);
  const handleOpenModal = () => setOpen(true);
  const handleCloseModal = () => setOpen(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setShowPassword((v) => !v);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleClickLogin = () => {
    setLoading(true);
    axios
      .post(process.env.REACT_APP_API_ENDPOINT + "/auth/login", values)
      .then(({ data }) => {
        localStorage.setItem("token", data.token);
        setLoading(false);
        navigate("/admin/dashboard");
      })
      .catch((err) => {
        setSnackbar({
          message: err.response.data.message,
          severity: "error",
          shouldOpen: !snackbar.shouldOpen,
        });
        setLoading(false);
      });
  };

  const handleOnClickForgottenPassword = () => {
    if (email === null) return;

    setLoadingForgottenPassword(true);
    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/auth/reset-password/${email}`)
      .then(({ data }) => {
        setOpen(false);
        setLoadingForgottenPassword(false);
        setSnackbar({
          message: data.message,
          severity: "success",
          shouldOpen: !snackbar.shouldOpen,
        });
      })
      .catch((err) => {
        console.log(err);
        setLoadingForgottenPassword(false);
        setOpen(false);
        setSnackbar({
          message: err.response.data.message,
          severity: "error",
          shouldOpen: !snackbar.shouldOpen,
        });
      });
  };

  return (
    <>
      <CustomSnackbar {...snackbar} />
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{ height: "100%" }}
      >
        <Paper>
          <Stack
            alignItems="center"
            spacing={4}
            sx={{ p: 4, minWidth: "280px" }}
          >
            <Typography variant="h5">Connexion</Typography>
            <TextField
              value={values.username}
              onChange={handleChange}
              id="username"
              label="Nom d'utilisateur ou Email"
              variant="outlined"
              name="username"
              fullWidth
            />
            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor="standard-adornment-password">
                Mot de passe
              </InputLabel>
              <OutlinedInput
                name="password"
                label="Mot de passe"
                id="standard-adornment-password"
                type={showPassword ? "text" : "password"}
                value={values.password}
                onChange={handleChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            {loading ? (
              <CircularProgress />
            ) : (
              <Button
                onClick={handleClickLogin}
                fullWidth
                variant="contained"
                size="large"
              >
                Connexion
              </Button>
            )}
            {loadingForgottenPassword ? (
              <CircularProgress />
            ) : (
              <Button
                sx={{ mt: "0.5rem" }}
                variant="text"
                onClick={handleOpenModal}
              >
                Mot de passe oublié
              </Button>
            )}
          </Stack>
        </Paper>
      </Stack>
      <Modal
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Paper sx={style}>
          <Stack spacing={3} alignItems="center">
            <Typography
              sx={{ color: "#212529", fontWeight: 400 }}
              id="modal-modal-title"
              variant="subtitle2"
            >
              Renseigner l'adresse email pour laquelle pour voulez réinitialiser
              le mot de passe.
            </Typography>
            <TextField
              required
              fullWidth
              value={email}
              onChange={({ target }) => setEmail(target.value)}
              id="email"
              label="Email"
              variant="outlined"
              name="email"
              size="small"
            />
            <Button
              onClick={handleOnClickForgottenPassword}
              variant="contained"
              endIcon={<SendIcon />}
            >
              Envoie email
            </Button>
          </Stack>
        </Paper>
      </Modal>
    </>
  );
};

export default Login;
