import {
  Alert,
  Button,
  CircularProgress,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { FC, useState, useEffect } from "react";
import CustomSnackbar, { SnackbarType } from "../layout/CustomSnackbar";
import axios from "axios";
import { useSearchParams, useNavigate } from "react-router-dom";

const ResetPassword: FC = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [snackbar, setSnackbar] = useState<SnackbarType>({
    severity: "error",
    message: "",
    shouldOpen: false,
  });
  const [passwords, setPasswords] = useState<{
    password: string;
    secondPassword: string;
  }>({
    password: "",
    secondPassword: "",
  });
  const [loading, setLoading] = useState(false);
  const [access, setAccess] = useState<{
    loading: boolean;
    accessGranted: boolean;
  }>({
    loading: true,
    accessGranted: false,
  });

  useEffect(() => {
    const token = searchParams.get("resetPasswordToken");
    if (!token) {
      setAccess({ loading: false, accessGranted: false });
      return;
    }

    axios
      .get(
        `${process.env.REACT_APP_API_ENDPOINT}/auth/check-reset-password-token/${token}`
      )
      .then(({ data }) => {
        setAccess({ loading: false, accessGranted: true });
      })
      .catch((err) => {
        setAccess({ loading: false, accessGranted: false });
      });
  }, [searchParams]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPasswords((passwords) => ({
      ...passwords,
      [event.target.name]: event.target.value,
    }));
  };

  const handleClickSavePassword = () => {
    if (passwords.password.length < 8) {
      setSnackbar({
        message: "Le mot de passe doit faire plus de 8 caractères",
        severity: "error",
        shouldOpen: !snackbar.shouldOpen,
      });
      return;
    }

    if (passwords.password !== passwords.secondPassword) {
      setSnackbar({
        message: "Les mots de passe ne sont pas identiques",
        severity: "error",
        shouldOpen: !snackbar.shouldOpen,
      });
      return;
    }

    const token = searchParams.get("resetPasswordToken");
    if (!token) {
      return setSnackbar({
        message: "Missing token",
        severity: "error",
        shouldOpen: !snackbar.shouldOpen,
      });
    }

    setLoading(true);
    axios
      .post(process.env.REACT_APP_API_ENDPOINT + "/auth/reset-password", {
        password: passwords.password,
        token,
      })
      .then(() => {
        setLoading(false);
        setSnackbar({
          shouldOpen: !snackbar.shouldOpen,
          severity: "success",
          message: "Password saved",
        });
        setTimeout(() => {
          navigate("../login");
        }, 1500);
      })
      .catch((err: any) => {
        setLoading(false);
        setSnackbar({
          message: err.response.data.message,
          severity: "error",
          shouldOpen: !snackbar.shouldOpen,
        });
      });
  };

  return (
    <>
      <CustomSnackbar {...snackbar}></CustomSnackbar>
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{ height: "100%" }}
      >
        {access.loading ? (
          <Stack alignItems="center" spacing={2}>
            <Alert severity="info">Vérification du token en cours</Alert>
            <CircularProgress></CircularProgress>
          </Stack>
        ) : access.accessGranted ? (
          <Paper>
            <Stack
              alignItems="center"
              spacing={4}
              sx={{ p: 4, minWidth: "280px" }}
            >
              <Typography variant="h5">
                Taper votre nouveau mot de passe
              </Typography>
              <TextField
                required
                value={passwords.password}
                onChange={handleChange}
                id="password"
                label="Mot de passe"
                variant="outlined"
                name="password"
                fullWidth
              />

              <TextField
                required
                value={passwords.secondPassword}
                onChange={handleChange}
                id="secondPassword"
                label="Confirmer le mot de passe"
                variant="outlined"
                name="secondPassword"
                fullWidth
              />

              {loading ? (
                <CircularProgress />
              ) : (
                <Button
                  onClick={handleClickSavePassword}
                  fullWidth
                  variant="contained"
                  size="large"
                >
                  Enregistrer
                </Button>
              )}
            </Stack>
          </Paper>
        ) : (
          <Alert severity="error">Token invalide</Alert>
        )}
      </Stack>
    </>
  );
};

export default ResetPassword;
