import { Stack, Typography } from "@mui/material";
import { FC } from "react";

const Users: FC = () => {
  return (
    <Stack>
      <Typography variant="h1">Users</Typography>
    </Stack>
  );
};

export default Users;
