import { Stack, Typography } from "@mui/material";
import { FC } from "react";

const UpdateUser: FC = () => {
  return (
    <Stack>
      <Typography variant="h1">Update user</Typography>
    </Stack>
  );
};

export default UpdateUser;
