import { FC, useEffect, useState } from "react";
import { Alert, AlertColor, Snackbar } from "@mui/material";

export interface SnackbarType {
  severity: AlertColor;
  message: string;
  shouldOpen: boolean;
}

const CustomSnackbar: FC<SnackbarType> = (props) => {
  const [snackbar, setSnackbar] = useState({ ...props, open: false });

  useEffect(() => {
    if (props.message.length === 0) return;
    setSnackbar({ ...props, open: true });
  }, [props.shouldOpen, props.severity, props.message]);

  const handleCloseSnackbar = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={snackbar.open}
      autoHideDuration={2000}
      onClose={handleCloseSnackbar}
    >
      <Alert
        onClose={handleCloseSnackbar}
        severity={snackbar.severity}
        sx={{ width: "100%" }}
      >
        {snackbar.message}
      </Alert>
    </Snackbar>
  );
};

export default CustomSnackbar;
